import { Link, Navigate, useSearchParams } from "react-router-dom";
import { Button, Icon } from "../../../atoms/components";
import { gql, useQuery } from "@apollo/client";
import {
  useTimeChancesV2Query,
  ResourceViewV2Query,
} from "../../../generated/graphql";
import { ResourceContentAssessmentV2 } from "../../../pages/components/AssessmentViewerV2";
import { useEffect, useState } from "react";
import {
  LoadingCentered,
  SubmissionFeedback,
} from "../../../molecules/components";

import { useNavigate } from "react-router-dom";
export interface TimeChancesPromptProps {
  retake?: boolean;
  timer?: boolean;
  chances?: boolean;
  resourceUuid?: string;
  blockUuid?: string;
  courseUuid?: string;
  scheduleUuid?: string;
  totalScore?: number;
  maxScore?: number;
  onCancel?: () => void;
  results?: "passed" | "failed" | "pending" | undefined;
}

export function TimeChancesPrompt({
  retake,
  totalScore,
  maxScore,
  resourceUuid,
  blockUuid,
  courseUuid,
  scheduleUuid,
  results,
  onCancel,
}: TimeChancesPromptProps) {
  const [display, setDisplay] = useState(false);
  const secondsToHms = (seconds: number | undefined | null) => {
    if (!seconds) return;
    seconds = Number(seconds);
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const _seconds = Math.floor((seconds % 3600) % 60);
    const displayHours =
      hours > 0 ? hours + (hours === 1 ? " hour" : " hours") : " ";
    const displayMinute =
      minutes > 0 ? minutes + (minutes === 1 ? " minute" : " minutes") : " ";
    const displaySeconds =
      _seconds > 0 ? _seconds + (_seconds === 1 ? " second" : " seconds") : "";
    return displayHours + " " + displayMinute + " " + displaySeconds;
  };

  const { data, loading, error, refetch } = useTimeChancesV2Query({
    variables: {
      resourceDetailsInput: {
        resourceUuid: resourceUuid || "",
        courseBlockUuid: blockUuid || "",
        scheduleUuid: scheduleUuid || "",
      },
    },
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  });

  const referenceInfo = data?.resourceViewScreenV2?.courseBlock?.referenceInfo;
  const courseBlock = data?.resourceViewScreenV2?.courseBlock;
  const resource = data?.resourceViewScreenV2?.resource;

  const content: ResourceContentAssessmentV2 =
    (resource?.content as ResourceContentAssessmentV2) || {};

  const passingMark = referenceInfo?.customPassingMark;
  const highestPossibleScore =
    content.examTimeslot?.outline?.exam?.highestPossibleScore || 0;
  const passOnSubmit = referenceInfo?.passOnSubmit;
  const userStatus = courseBlock?.userstatus || null;
  const timeLimit = content?.examTimeslot?.outline?.exam?.timeLimit || null;
  const subtype = resource?.subtype;

  const maxAllowedSubmissions = referenceInfo?.maxAllowedSubmissions || 0;

  const totalSubmissions =
    (courseBlock?.userstatus?.submissionCount || 0) -
    (userStatus?.submissionStatus === "draft" ? 1 : 0);

  const [searchParams] = useSearchParams();
  const pathArray = window.location.pathname.split("/");
  const parentScreenNameOutsideCourse = pathArray.pop();
  const parentScreenNameInsideCourse = searchParams.get("from_screen");
  const parentScreenName =
    parentScreenNameInsideCourse || parentScreenNameOutsideCourse;
  const navigate = useNavigate();

  const submissionLength =
    content.examTimeslot?.userSubmissions?.final?.submissions?.length;
  const noExistingSubmission =
    submissionLength === undefined ||
    submissionLength === null ||
    submissionLength === 0;

  const firstTimeShowPassingGrade =
    (content.examTimeslot?.userSubmissions?.final?.count || 0) === 0;

  const finalSubmissionCount =
    content.examTimeslot?.userSubmissions?.final?.count || 0;

  const confirmOrRetakeUi =
    firstTimeShowPassingGrade ||
    userStatus?.submissionStatus === "draft" ||
    userStatus?.submissionStatus === "in_progress";

  useEffect(() => {
    if (
      (!loading &&
        data &&
        !timeLimit &&
        !firstTimeShowPassingGrade &&
        !maxAllowedSubmissions &&
        noExistingSubmission) ||
      subtype === "file-upload" ||
      subtype === "freedom-wall" ||
      (passOnSubmit && !timeLimit && !maxAllowedSubmissions)
    ) {
      if (!blockUuid) {
        navigate({
          pathname: "/view",

          search: `?resource_uuid=${resourceUuid}&from_screen=${parentScreenName} ${
            parentScreenName === "search"
              ? `&search_value=${searchParams.get("search_value")}`
              : ""
          }`,
        });
      } else {
        navigate({
          pathname: "/view",

          search: `?course_block_uuid=${blockUuid}${
            resourceUuid
              ? `&resource_uuid=${resourceUuid}&course_uuid=${courseUuid}&course_schedule_uuid=${scheduleUuid}&from_screen=${parentScreenName}${
                  parentScreenName === "search"
                    ? `&search_value=${searchParams.get("search_value")}`
                    : ""
                }`
              : ""
          }`,
        });
      }
      if (
        subtype === "file-upload" ||
        subtype === "freedom-wall" ||
        results !== "pending"
      ) {
        onCancel?.();
      }
    } else {
      setDisplay(true);
    }
  }, [loading]);

  if (loading) {
    return (
      <div className="relative mx-auto w-72 h-72">
        <LoadingCentered></LoadingCentered>
      </div>
    );
  }

  if (!display) {
    return <></>;
  }
  return (
    <>
      {display ? (
        <div>
          {
            <div className="w-full mx-auto min-h-fit max-h-[75vh] overflow-y-auto">
              <TimeChancesHeader
                passOnSubmit={passOnSubmit || false}
                finalSubmissionCount={finalSubmissionCount}
              />
              {/* Retake */}
              {(totalSubmissions !== 0 ||
                retake ||
                userStatus?.submissionStatus === "in_progress" ||
                finalSubmissionCount > 0) && (
                <ScoreUi
                  results={userStatus?.status || ""}
                  totalScore={
                    (content?.examTimeslot?.userSubmissions?.final
                      ?.submissions?.[0]?.totalScore as number) || null
                  }
                  // totalScore={(0 as number) || null || 0}
                  passOnSubmit={passOnSubmit || false}
                  passingMark={passingMark || 50}
                  highestPossibleScore={highestPossibleScore || 0}
                />
              )}
              <FirstTakeUi
                finalSubmissionCount={finalSubmissionCount}
                highestPossibleScore={highestPossibleScore}
                passingMark={passingMark}
                passOnSubmit={passOnSubmit || false}
              />
              <div className={!passOnSubmit ? "md:flex justify-between" : ""}>
                {/* Timer Part Here */}
                {!!timeLimit && timeLimit > 0 && (
                  <div
                    className={
                      maxAllowedSubmissions && !passOnSubmit
                        ? "block md:w-1/2 md:mr-2"
                        : "w-full"
                    }
                  >
                    <div className="py-2 text-center rounded bg-secondary-100">
                      <div className="flex justify-center w-full my-2 text-sm text-secondary-500">
                        <Icon name="timer" />
                        &nbsp;
                        <div className="uppercase">Exam Timer</div>
                      </div>
                      <div className="my-4 text-2xl font-bold text-secondary-600">
                        {secondsToHms(timeLimit)}
                      </div>
                    </div>
                    <div className="pb-4 my-2 text-sm text-center">
                      <b>Timer will start</b> when the resource is opened
                    </div>
                  </div>
                )}
                {/* Chances Part Here */}
                {!!maxAllowedSubmissions && maxAllowedSubmissions > 0 && (
                  <div
                    className={
                      timeLimit && !passOnSubmit ? "block md:w-1/2" : "w-full"
                    }
                  >
                    <div className="py-2 text-center rounded bg-secondary-100">
                      <div className="flex justify-center w-full my-2 text-sm text-secondary-500">
                        <Icon name="refresh" />
                        &nbsp;
                        <div className="uppercase"> Chances Left</div>
                      </div>
                      <div className="my-4 text-2xl font-bold text-secondary-600">
                        {maxAllowedSubmissions - totalSubmissions < 0
                          ? 0
                          : maxAllowedSubmissions - totalSubmissions}{" "}
                        of {maxAllowedSubmissions}
                      </div>
                    </div>
                    <div className="pb-4 my-2 text-sm text-center">
                      Upon confirming, <b>chances will be spent</b> and this
                      action cannot be undone
                    </div>
                  </div>
                )}
              </div>
              {/* Buttons Here */}
              <div className="flex justify-center my-4">
                {blockUuid ? (
                  <Link
                    to={`/view?course_block_uuid=${blockUuid}&resource_uuid=${resourceUuid}&course_uuid=${courseUuid}&course_schedule_uuid=${scheduleUuid}&from_screen=${parentScreenNameInsideCourse}`}
                    state={{ scrollYValue: window.scrollY }}
                    reloadDocument
                    onClick={() => onCancel?.()}
                  >
                    <Button
                      className={
                        confirmOrRetakeUi
                          ? "mr-2 uppercase transition-colors duration-300 ease-out bg-white border border-secondary-700 text-secondary-700 hover:text-white hover:bg-secondary-600 hover:border-white"
                          : "mr-2 uppercase transition-colors duration-300 ease-out bg-white border border-error-600 text-error-600 hover:text-white hover:bg-error-500 hover:border-white"
                      }
                      disabled={
                        loading ||
                        (!!maxAllowedSubmissions &&
                          totalSubmissions >= maxAllowedSubmissions)
                      }
                    >
                      {confirmOrRetakeUi ? "Confirm" : "Retake"}
                    </Button>
                  </Link>
                ) : (
                  <Link
                    to={`/view?resource_uuid=${resourceUuid}&from_screen=${parentScreenName} `}
                    reloadDocument
                  >
                    <Button
                      className={
                        confirmOrRetakeUi
                          ? "mr-2 uppercase transition-colors duration-300 ease-out bg-white border border-secondary-700 text-secondary-700 hover:text-white hover:bg-secondary-600 hover:border-white"
                          : "mr-2 uppercase transition-colors duration-300 ease-out bg-white border border-error-600 text-error-600 hover:text-white hover:bg-error-500 hover:border-white"
                      }
                      disabled={
                        loading ||
                        (!!maxAllowedSubmissions &&
                          totalSubmissions >= maxAllowedSubmissions)
                      }
                    >
                      {confirmOrRetakeUi ? "Confirm" : "Retake"}
                    </Button>
                  </Link>
                )}
                <Button
                  className="ml-2 text-white uppercase border rounded bg-secondary-600 hover:bg-secondary-700"
                  onClick={() => onCancel?.()}
                >
                  cancel
                </Button>
              </div>
            </div>
          }
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export interface TimeChancesHeaderProps {
  passOnSubmit: boolean;
  finalSubmissionCount: number;
}

export function TimeChancesHeader({
  passOnSubmit,
  finalSubmissionCount,
}: TimeChancesHeaderProps) {
  // If there is no submissions yet or if pass_on_submit === true
  if (!finalSubmissionCount || passOnSubmit) return null;

  return (
    <h1 className="flex justify-center mb-4 text-2xl font-bold">
      Are you sure?
    </h1>
  );
}

export interface FirstTakeUiProps {
  finalSubmissionCount: number;
  highestPossibleScore: number;
  passingMark: number | null | undefined;
  passOnSubmit: boolean;
}

export function FirstTakeUi({
  finalSubmissionCount,
  highestPossibleScore,
  passingMark,
  passOnSubmit,
}: FirstTakeUiProps) {
  if (!!finalSubmissionCount || passOnSubmit) return null;

  return (
    <div className="p-4 mb-2 rounded bg-secondary-100 text-secondary-700">
      <h1 className="mb-4 text-sm text-center uppercase ">
        Passing Score For This Assessment
      </h1>
      <p className="text-4xl font-black text-center">
        {highestPossibleScore && passingMark
          ? Math.ceil(highestPossibleScore * (passingMark / 100))
          : ""}{" "}
        <span className="text-2xl">{`pt(s)`}</span>
      </p>
    </div>
  );
}

export interface ScoreUiProps {
  results: string | null;
  highestPossibleScore: number | null;
  totalScore: number | null;
  passingMark: number;
  passOnSubmit: boolean;
}

export function ScoreUi({
  results,
  highestPossibleScore,
  totalScore,
  passingMark,
  passOnSubmit,
}: ScoreUiProps) {
  // if (passOnSubmit || !maxScore) return null;

  const [searchParams] = useSearchParams();
  const isInCourse = !!searchParams.get("course_uuid");

  // Time Chances UI Outside Course
  if (!isInCourse)
    return (
      <>
        <SubmissionFeedback
          results={"passed"}
          maxScore={highestPossibleScore}
          totalScore={totalScore}
          passOnSubmit={false}
          inTimer={true}
          passingMark={0}
        />
        <div className="pb-4 my-2 text-sm text-center">
          Retaking this resource will <b>replace your current score.</b>{" "}
          Previous scores can not be swapped and retrieved
        </div>
      </>
    );

  // Time Changes UI Inside Course
  return (
    <>
      <SubmissionFeedback
        results={results}
        maxScore={highestPossibleScore}
        totalScore={totalScore}
        passOnSubmit={passOnSubmit || false}
        inTimer={true}
        passingMark={passingMark || 50}
      />
      <div className="pb-4 my-2 text-sm text-center">
        Retaking this resource will <b>replace your current score.</b> Previous
        scores can not be swapped and retrieved
      </div>
    </>
  );
}
